import classnames from 'classnames';
import { Entry } from 'contentful';
import idx from 'idx';

import { getContentTypeId } from 'components/contentfulModules/utils';
import {
  DynamicModulesManager,
  PageTrackingIdentifier,
} from 'components/DynamicModules/DynamicModulesManager';
import { Column, Container, Row } from 'components/Grid';

import { ContentfulContentTypeEnum, HomePageEntry } from 'lib/contentful';
import { renderContentfulEntry } from 'lib/contentful/component';
import Logger from 'lib/utils/Logger';

import { DynamicModuleResponse } from 'types/generated/api';

import styles from './HomeLanding.module.scss';

type HomeLandingProps = {
  dynamicModulesResponse: DynamicModuleResponse[];
  homePageEntry: HomePageEntry;
};
type ContentfulContentType = Record<ContentfulContentTypeEnum, string>;

const ABOUT_US_TEXT_BANNER_ID = '433LcPpwr9GcqMnWeSaGE6';

const HomeLanding = (props: HomeLandingProps) => {
  const { dynamicModulesResponse } = props;
  const { modules } = props.homePageEntry.fields;

  return (
    <Container>
      <Row>
        <Column md={10} offset={{ md: 1, xs: 0 }} xs={12}>
          {modules.map((mod, index) => {
            const entryId = idx(mod, _ => _.sys.id);

            if (entryId === ABOUT_US_TEXT_BANNER_ID) {
              return false;
            }

            const isFirstModule = index === 0;
            const isTextBannerModule = checkIsModuleType(
              mod,
              ContentfulContentTypeEnum.moduleTextBanner
            );

            // if previous module is a skinny banner, give this one a smaller top padding
            const isPreviousModuleSkinnyBanner =
              index > 0 &&
              checkIsModuleType(
                modules[index - 1],
                ContentfulContentTypeEnum.moduleSkinnyBanner
              );

            const className = classnames({
              [styles.firstModuleTopMargin]: isFirstModule,
              [styles.moduleTopMargin]:
                !isTextBannerModule &&
                !isFirstModule &&
                !isPreviousModuleSkinnyBanner,
              [styles.moduleSmallerTopMargin]: isPreviousModuleSkinnyBanner,
              [styles.textBannerTopMargin]: isTextBannerModule,
            });

            if (isFirstModule) {
              return renderContentfulEntry(mod, {
                className,
                moduleIndex: index,
              });
            }

            return (
              <div key={mod?.sys?.id}>
                {renderContentfulEntry(mod, {
                  className,
                  moduleIndex: index,
                })}
              </div>
            );
          })}
        </Column>
      </Row>
      <Row>
        <Column md={10} offset={{ md: 1, xs: 0 }} xs={12}>
          <div className={styles.moduleTopMargin}>
            <DynamicModulesManager
              dynamicModulesResponse={dynamicModulesResponse}
              pageTrackingIdentifier={PageTrackingIdentifier.HOMEPAGE}
            />
          </div>
        </Column>
      </Row>
    </Container>
  );
};

const checkIsModuleType = (
  mod: Entry<unknown>,
  moduleType: keyof ContentfulContentType
) => {
  try {
    const contentTypeId = getContentTypeId(mod);
    return contentTypeId === moduleType;
  } catch (error) {
    Logger.warn(`Unable to determine if module is a ${moduleType}`, error);
    return false;
  }
};

export default HomeLanding;
