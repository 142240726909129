import ErrorPageBody from './ErrorPageBody/ErrorPageBody';
import { Column, Container, Row } from 'components/Grid';
import Page from 'components/Page/Page';
import NotFoundPage from 'containers/NotFoundPage';

import { MetaTags } from 'lib/routes/metaTags';

import { SiteFurniture } from 'types/app';

type ErrorPageProps = {
  siteFurniture: SiteFurniture;
  statusCode?: number;
};

const metaTags: MetaTags = {
  canonicalUrl: '',
  title: 'Error',
};

const ErrorPage = ({ siteFurniture, statusCode }: ErrorPageProps) => {
  // if a component returned 404, render 'Not Found' instead of 'Error'
  if (statusCode === 404) {
    return <NotFoundPage siteFurniture={siteFurniture} />;
  }

  return (
    <Page metaTags={metaTags} siteFurniture={siteFurniture}>
      <Container>
        <Row>
          <Column xs={12}>
            <ErrorPageBody />
          </Column>
        </Row>
      </Container>
    </Page>
  );
};

export default ErrorPage;
