import compact from 'lodash/compact';
import { JsonLd } from 'react-schemaorg';
import { ContactPoint, Organization } from 'schema-dts';

import { generateOrganization } from './utils';

import { BrandPageEntry } from 'lib/contentful';
import { generatePerson } from 'lib/metatags/person';
import { DEFAULT_IMAGE as verishopLogo } from 'lib/routes/metaTags';

type BrandLinkedData = {
  contactEmail?: string;
  contactPhone?: string;
  customerSupportEmail?: string;
  customerSupportNumber?: string;
  founders?: string[];
  foundingDate?: string;
  homepageUrl?: string;
  logo?: string;
  name: string;
  slug: string;
  socialUrls?: string[];
};

type GenerateBrandLinkedDataProps = {
  brandEntry?: BrandPageEntry;
  brandImageUrl?: string;
  brandName: string;
};

export const generateBrandLinkedData = ({
  brandEntry,
  brandImageUrl,
  brandName,
}: GenerateBrandLinkedDataProps) => {
  const brandContent = brandEntry?.fields;

  if (!brandContent || !brandName) {
    return null;
  }

  const linkedDataProps: BrandLinkedData = {
    homepageUrl: brandContent.homepageUrl,
    logo: brandImageUrl,
    name: brandName,
    slug: brandContent.slug,
    socialUrls: compact([
      brandContent.facebookUrl,
      brandContent.instagramUrl,
      brandContent.twitterUrl,
    ]),
  };

  return <BrandOrganizationLinkedData {...linkedDataProps} />;
};

export const BrandOrganizationLinkedData = (props: BrandLinkedData) => {
  const { homepageUrl, logo, name, slug, socialUrls = [] } = props;

  if (!name) {
    return null;
  }

  const linkedDataItem: Organization = {
    '@type': 'Organization',
    name,
  };
  if (homepageUrl) {
    linkedDataItem.url = homepageUrl;
  } else if (slug) {
    linkedDataItem.url = `https://www.verishop.com/brand/${slug}`;
  }
  if (logo) {
    linkedDataItem.logo = logo;
  }
  if (socialUrls && socialUrls.length) {
    linkedDataItem.sameAs = socialUrls;
  }

  return (
    <JsonLd<Organization>
      item={{
        '@context': 'https://schema.org',
        ...linkedDataItem,
      }}
    />
  );
};

const verishopFunders = [
  generateOrganization({ name: 'Lightspeed Venture Partners' }),
  generateOrganization({ name: 'Madrona Venture Group' }),
  generateOrganization({ name: 'Rakuten' }),
  generateOrganization({ name: 'Upfront Ventures' }),
  generatePerson({
    gender: 'Male',
    jobTitle: 'Partner at Lightspeed',
    name: 'Jeremy Liew',
  }),
  generatePerson({
    gender: 'Male',
    jobTitle: 'Chairman of Vision Fund',
    name: 'Mark Schwartz',
  }),
  generatePerson({
    gender: 'Male',
    jobTitle: 'Former CEO of HBO',
    name: 'Richard Plepler',
  }),
  generatePerson({
    gender: 'Male',
    jobTitle: "Former CEO of Sotheby's",
    name: 'Tad Smith',
  }),
];

const verishopContactPoint: ContactPoint = {
  '@type': 'ContactPoint',
  contactOption: 'TollFree',
  contactType: 'customer service',
  telephone: '+1-888-364-5828',
};
const verishopSocialUrls = [
  'https://angel.co/company/verishop',
  'https://instagram.com/verishop',
  'https://twitter.com/Verishop',
  'https://www.facebook.com/verishop',
  'https://www.linkedin.com/company/verishop/',
  'https://www.crunchbase.com/organization/verishop',
];

export const VerishopOrganizationLinkedData = () => {
  return (
    <JsonLd<Organization>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Organization',
        contactPoint: [verishopContactPoint],
        email: 'support@verishop.com',
        founders: [
          generatePerson({
            alumniOf: 'University of Denver',
            gender: 'Male',
            honorificSuffix: 'PhD',
            jobTitle: 'CEO of Verishop',
            name: 'Imran Khan',
            nationality: 'Bangladeshi',
          }),
          generatePerson({
            alumniOf: 'The Wharton School, University of Pennsylvania',
            gender: 'Female',
            jobTitle: 'CSO of Verishop',
            name: 'Cate Khan',
            nationality: 'American',
          }),
        ],
        foundingDate: '2018-11-05',
        funder: verishopFunders,
        logo: verishopLogo,
        name: 'Verishop',
        sameAs: verishopSocialUrls,
        url: 'https://www.verishop.com/',
      }}
    />
  );
};
