import Head from 'next/head';

import NotFoundPageBody from './NotFoundPageBody';
import { Column, Container, Row } from 'components/Grid';
import Page from 'components/Page/Page';

import { SiteFurniture } from 'types/app';

type NotFoundPageProps = {
  siteFurniture: SiteFurniture;
};

const NotFoundPage = ({ siteFurniture }: NotFoundPageProps) => (
  <Page siteFurniture={siteFurniture} title="Not found">
    <Head>
      <meta content="noindex" name="robots" />
      <meta content="404" name="prerender-status-code" />
    </Head>
    <Container>
      <Row>
        <Column xs={12}>
          <NotFoundPageBody />
        </Column>
      </Row>
    </Container>
  </Page>
);

export default NotFoundPage;
