import SearchBar from 'components/SearchBar/SearchBar';

import { CategoryLink, HomeLink, TastemakersLink } from 'lib/links';

import styles from './NotFound.module.scss';

const NotFoundPageBody = () => (
  <div className={styles.container}>
    <div className={styles.heading}>Oops! We couldn't find this page.</div>
    <div className={styles.subHeading}>
      Make sure you typed the URL correctly, or try searching.
    </div>
    <div className={styles.searchBar}>
      <SearchBar disableSuggestions setIsShowing={() => false} />
    </div>
    <div className={styles.moreLinks}>
      <div>Or let us point you in a better direction:</div>

      <div className={styles.linkWrapper}>
        <HomeLink>
          <a className={styles.link}>Home</a>
        </HomeLink>
      </div>

      <div className={styles.linkWrapper}>
        <CategoryLink categorySlug="men">
          <a className={styles.link}>Men</a>
        </CategoryLink>
      </div>

      <div className={styles.linkWrapper}>
        <CategoryLink categorySlug="women">
          <a className={styles.link}>Women</a>
        </CategoryLink>
      </div>

      <div className={styles.linkWrapper}>
        <CategoryLink categorySlug="beauty">
          <a className={styles.link}>Beauty</a>
        </CategoryLink>
      </div>

      <div className={styles.linkWrapper}>
        <TastemakersLink>
          <a className={styles.link}>Tastemakers</a>
        </TastemakersLink>
      </div>
    </div>
  </div>
);

export default NotFoundPageBody;
