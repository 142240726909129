import { ReactElement } from 'react';

import Carousel from 'components/Carousel/Carousel';

import styles from './DynamicModuleProductCarousel.module.scss';
import productCardImageStyles from 'components/ProductGrid/ProductCard/ProductCardImage/ProductCardImage.module.scss';
import sharedProductCardStyles from 'components/ProductGrid/sharedProductCardStyles.module.scss';

type DynamicModuleProductCarouselProps = {
  children: ReactElement | ReactElement[];
};

export const DynamicModuleProductCarousel = ({
  children,
}: DynamicModuleProductCarouselProps) => {
  return (
    <Carousel
      className={styles.dynamicModuleProductCarouselRoot}
      classNameForArrowContainerMarginTop={sharedProductCardStyles.root}
      classNameForCenteringArrows={productCardImageStyles.image}
    >
      {children}
    </Carousel>
  );
};
