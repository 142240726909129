import { JsonLd } from 'react-schemaorg';
import { WebSite } from 'schema-dts';

export const HomepageLinkedData = () => (
  <>
    <JsonLd<WebSite>
      item={{
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        potentialAction: {
          '@type': 'SearchAction',
          'query-input': 'required name=search_term_string',
          target: 'https://www.verishop.com/search?q={search_term_string}',
        },
        url: 'https://www.verishop.com/',
      }}
    />
    <meta content="3583efd23247f82b9c0a0364be0014d4" name="p:domain_verify" />
  </>
);
