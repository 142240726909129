import { DynamicModuleHeader } from 'components/DynamicModules/DynamicModuleHeader/DynamicModuleHeader';
import { DynamicModuleProductCarousel } from 'components/DynamicModules/DynamicModuleProductCarousel/DynamicModuleProductCarousel';
import {
  DynamicModuleData,
  PageTrackingIdentifier,
} from 'components/DynamicModules/DynamicModulesManager';
import { Column } from 'components/Grid';
import ProductCard from 'components/ProductGrid/ProductCard/ProductCard';

type GeneralDynamicModuleProps = {
  dynamicModuleData: DynamicModuleData;
  pageTrackingIdentifier: PageTrackingIdentifier;
};

export const GeneralDynamicModule = ({
  dynamicModuleData,
  pageTrackingIdentifier,
}: GeneralDynamicModuleProps) => {
  const { module, products, segment } = dynamicModuleData;
  const trackingIdentifier = `${pageTrackingIdentifier}-${module}-${segment}`;

  const shouldRender = products && products.length !== 0;

  const getTitle = () => {
    switch (segment) {
      case 'BEST_SELLERS': {
        return 'Shop Best Sellers';
      }
      default: {
        return 'Shop Recommended Products';
      }
    }
  };

  const propagateProductCards = () => {
    return (
      products?.map((product, index) => {
        return (
          <Column key={`${product.sid}-${index}`} md={2} sm={4} xs={8}>
            <ProductCard
              bookmarksByProductSid={[]}
              hideBookmarkButton
              index={index}
              productCardData={product}
              trackingIdentifier={trackingIdentifier}
            />
          </Column>
        );
      }) || []
    );
  };

  const generalDynamicModuleContainer = (
    <div>
      <DynamicModuleHeader header={getTitle()} />
      {shouldRender && (
        <DynamicModuleProductCarousel>
          {propagateProductCards()}
        </DynamicModuleProductCarousel>
      )}
    </div>
  );

  return <>{shouldRender && generalDynamicModuleContainer}</>;
};
