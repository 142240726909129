import { ProductOptionTypeEnum } from 'data/graphql/types';

import {
  Image,
  Product,
  ProductOption,
  ProductOptionValue,
  ProductVariant,
  SelectedOption,
} from '../ProductCardApiQuery.gql';
import {
  ProductCardData,
  ProductCardDimension,
  ProductCardVariant,
  ProductCardVariantDimensionOption,
} from '../types';

export const convertApiProductToProductCardData = (
  apiProduct: Product,
  index: number
): ProductCardData => {
  const converter = new ApiProductConverterUtil(apiProduct, index);
  return converter.convertApiProductToProductCardData();
};

class ApiProductConverterUtil {
  apiProduct: Product;
  apiProductVariants: ProductVariant[];
  index: number;

  constructor(apiProduct: Product, index: number) {
    this.apiProduct = apiProduct;
    this.apiProductVariants = apiProduct.variants;
    this.index = index;
  }

  convertApiProductToProductCardData = (): ProductCardData => {
    const {
      brand,
      brandSlug,
      categories,
      family,
      familySlug,
      options,
      sid,
      slug,
      title,
      variants,
    } = this.apiProduct;

    const firstVariant = variants[0];
    const {
      compareAtPrice,
      currencyCode,
      filterables,
      galleryImages,
      inStock,
      offerDetails,
      price,
      shoppingStatus,
      sid: variantSid,
      sku,
    } = firstVariant;

    return {
      brand,
      brandSlug,
      categories: categories?.[0]?.category ?? [],
      compareAtPrice,
      currencyCode,
      dimensions:
        options?.map(this.convertApiOptionToProductCardDimension) ?? [],
      eligiblePromotions: [],
      family,
      familySlug,
      filterables,
      imageUrl: galleryImages?.[0]?.url,
      inStock,
      offerDetails,
      position: this.index,
      price,
      productCategories: [categories?.[0]?.category ?? []],
      productSid: sid,
      productSlug: slug,
      shoppingStatus,
      sid: variantSid,
      sku,
      title,
      variantTitle: firstVariant.title,
      variants: variants.map(this.convertApiProductVariantToProductCardVariant),
    };
  };

  convertApiOptionToProductCardDimension = (
    apiOption: ProductOption
  ): ProductCardDimension => {
    return {
      ...apiOption,
      values: apiOption.values.map(value => {
        return {
          ...value,
          preview: {
            images: this.getImagesForProductCardOptionValue(value, apiOption),
          },
        };
      }),
    };
  };

  getImageUrlFromImages(images: Image[]): string[] {
    return images.map(image => image.url);
  }

  getImagesForProductCardOptionValue = (
    value: ProductOptionValue,
    option: ProductOption
  ) => {
    if (option.type === ProductOptionTypeEnum.Sizes) {
      const firstVariant = this.apiProductVariants[0];
      const defaultImages = firstVariant.galleryImages;

      return this.getImageUrlFromImages(defaultImages);
    }

    const variantsWithMatchingOptionValue = this.apiProductVariants.filter(
      variant => {
        return variant.selectedOptions.some(
          selectedOption => selectedOption.value === value.value
        );
      }
    );

    return this.getImageUrlFromImages(
      variantsWithMatchingOptionValue?.[0]?.galleryImages ?? []
    );
  };

  convertApiProductVariantToProductCardVariant = (
    apiProductVariant: ProductVariant
  ): ProductCardVariant => {
    return {
      compareAtPrice: apiProductVariant.compareAtPrice,
      inStock: apiProductVariant.inStock,
      price: apiProductVariant.price,
      sid: apiProductVariant.sid,
      values:
        apiProductVariant.selectedOptions?.map(
          this.convertApiSelectedOptionToProductCardDimensionOption
        ) ?? [],
    };
  };

  convertApiSelectedOptionToProductCardDimensionOption = (
    selectedOption: SelectedOption
  ): ProductCardVariantDimensionOption => {
    return convertApiSelectedOptionToProductCardDimensionOption(
      selectedOption,
      this.apiProduct.options
    );
  };
}

export const convertApiSelectedOptionToProductCardDimensionOption = (
  selectedOption: SelectedOption,
  apiOptions: ProductOption[]
): ProductCardVariantDimensionOption => {
  const matchingOption = apiOptions.find(
    option => option.name === selectedOption.name
  );

  const matchingOptionValue = matchingOption?.values.find(optionValue => {
    return optionValue.value === selectedOption.value;
  });

  return {
    label: matchingOptionValue?.label ?? '',
    name: selectedOption.name,
    value: selectedOption.value,
  };
};
